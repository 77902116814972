import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import moment from "moment";
import { appRouter } from "./app.routes";
import { AuthInterceptor } from "./core/services/interceptors/auth.interceptor";
import { HttpErrorInterceptor } from "./core/services/interceptors/http-error.interceptor";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { getPaginatorIntl } from "./core/misc/paginator-intl";
import { AppComponent } from "./app.component";
import { AuthService } from "./core/services/auth.service";
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from "@angular/material/button";
import { MetrikaModule } from "ng-yandex-metrika";

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);
moment.locale('ru');

export function loadUserFactory(authService: AuthService) {
  return function () {
    if (authService.loadTokenIfExists()) {
        return Promise.resolve(null);
    } else {
      authService.removeToken();
      return Promise.resolve(null);
    }
  };
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      appRouter,
      MatDialogModule,
      MatButtonModule,
      MetrikaModule.forRoot(
        { id: 98354382, webvisor: true },
      ),
    ],
    providers: [
      { provide: LOCALE_ID, useValue: 'ru-RU' },
      {
          provide: APP_INITIALIZER,
          useFactory: loadUserFactory,
          multi: true,
          deps: [AuthService],
      },
      // добовляем токен при запросе
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      // перехват ошибок апи с бека
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
      { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
      provideHttpClient(withInterceptorsFromDi()),
  ] })
export class AppModule {}