import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

/** добовляем токен при запросе */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authService = this.injector.get(AuthService);
    if (authService.getToken()) {
      req = req.clone({
        url: req.url,
        headers: req.headers.set('Authorization', `Bearer ${authService.getToken()}`),
      });
    }
    return next.handle(req);
  }
}
