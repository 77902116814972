import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
  ]
})

export class ErrorPageComponent implements OnInit {

  constructor() {
  }

  public ngOnInit(): void {
  }

}
