import { ActivatedRouteSnapshot, CanActivateFn, PreloadAllModules, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { inject } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { SuccessComponent } from './components/success/success.component';
import { FaildComponent } from './components/faild/faild.component';
import { HttpClient } from '@angular/common/http';

const canActivateQueryParams: CanActivateFn = (  route: ActivatedRouteSnapshot,  state: RouterStateSnapshot,) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  if (authService.getToken()){
    return true;
  }
  // ?guid=70d81264-008d-4b59-8415-b5233d184279&clientId=70d81264-008d-4b59-8415-b5233d184279&successTenantUrl=partner-ui.travelpublic.ru&failTenantUrl=partner-ui.travelpublic.ru
  if ((Object.keys(route.queryParams).length)) {
    console.log(route.queryParams)
      authService.auth(route.queryParams);
      return true;
    } else {
      // const http = inject(HttpClient);
      // let token = http.get(`/v1/settings/authorize?ClientId=321312&SuccessTenantUrl=https://partner-ui.dev.travelpublic.ru&FailTenantUrl=https://partner-ui.dev.travelpublic.ru&SessionTimeoutUrl=https://travelb2b-ui.dev.vkomandirovke.online`,  {responseType: 'text',headers: {
      //   'Api-Key': `048ad47f-fddf-4181-b280-d8951083e397`,
      // }}).toPromise().then(() => {
      //   // 
      // });
      router.navigate(['/error']);
      return false;
    }
};

const canActivatePaymentParams: CanActivateFn = (  route: ActivatedRouteSnapshot,  state: RouterStateSnapshot,) => {
  const router = inject(Router);
  // ?guid=70d81264-008d-4b59-8415-b5233d184279&clientId=70d81264-008d-4b59-8415-b5233d184279&successTenantUrl=partner-ui.travelpublic.ru&failTenantUrl=partner-ui.travelpublic.ru
  if (!!route.queryParams['paymentStatus']) {
      return true;
    } else {
      router.navigate(['/air-search']);
      return false;
    }
};

export const routes: Routes = [
  { path: 'error', component: ErrorPageComponent },
  { path: 'payment-success', canActivate: [canActivatePaymentParams], component: SuccessComponent },
  { path: 'payment-faild', canActivate: [canActivatePaymentParams], component: FaildComponent }, 
  {
    path: '',
    canActivate: [canActivateQueryParams],
    children: [
      { path: '', redirectTo: 'air-search', pathMatch: 'full' },
      {
        path: 'air-search',
        loadChildren: () => import('./air-module/air.module').then(m => m.AirModule),
      },
    ],
  },
];

export const appRouter = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
  scrollPositionRestoration: 'enabled',
});