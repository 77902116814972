import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'faild',
  templateUrl: './faild.component.html',
  styleUrls: ['./faild.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    MatButtonModule
  ]
})

export class FaildComponent implements OnInit {

  constructor(
    private _authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
  }

  exit() {
    window.location.href = `https://${this._authService.tokenDetails.failUrl}`;
  }
}
