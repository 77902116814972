<div class="container">
  <div class="success-page pl-20 pr-20">
    <div class="row">
      <mat-icon>check_circle</mat-icon>
      <div class="title">Бронирование прошло успешно!</div>
    </div>
    
    <button
      type="button"
      mat-raised-button
      [style.width]="'100%'"
      class="d-block mat-btn mat-btn-main search-button-width"
      (click)="exit()">
      Покинуть авиа сервис 
    </button>
  </div>
</div>