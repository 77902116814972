import { Injectable } from '@angular/core';
import { IHttpError } from '@app/core/interfaces/http-error.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { PopoverMessage } from '../class/popover-message';
import { ApiError } from '../class/api-error';

/**
 * сервис шины данных
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  /** канал для передачи ошибок */
  private _errorsChannel$: Subject<ApiError | IHttpError | null>;

  /** канал для передачи сообщений */
  private _messagesChannel$: Subject<PopoverMessage | null>;

  /** канал передачи ошибок апи */
  public get errorsChannel(): BehaviorSubject<ApiError | IHttpError> {
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    return <BehaviorSubject<ApiError | IHttpError>>this._errorsChannel$;
  }

  /** канал передачи различных сообщений для показа пользователю */
  public get messagesChannel(): BehaviorSubject<PopoverMessage> {
    return <BehaviorSubject<PopoverMessage>>this._messagesChannel$;
  }

  constructor() {
    this._errorsChannel$ = new BehaviorSubject<null | ApiError | IHttpError>(null);
    this._messagesChannel$ = new BehaviorSubject<PopoverMessage | null>(null);
  }

  /**
   * отослать сообщение об ошибке в канал
   * @param value данные ошибки
   */
  public emitError(value: ApiError | IHttpError): void {
    this._errorsChannel$.next(value);
  }

  /**
   * отослать сообщение в канал
   * @param data данные сообщения
   */
  public sendMessage(data: PopoverMessage): void {
    this._messagesChannel$.next(data);
  }
}
