import { IPopoverMessage } from "../interfaces/popover-message.model";

/**
 * класс для всплывающих сообщений
 */
export class PopoverMessage implements IPopoverMessage {
  public title = '';

  public isDanger = false;

  public additional = '';

  public noButtons = false;

  public isOfflineMessage = false;

  constructor(data?: IPopoverMessage) {
    if (!data) return;
    Object.assign(this, data);
  }
}
