import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { Utils } from '@app/core/misc/utils';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  /** базовый урл */
  private readonly _baseUrl: string = 'version';

  /** версия рабочего api */
  public hotelVersion = 2;

  constructor(private _api: ApiService, private _route: ActivatedRoute, private _router: Router) {}

  /** актуальная версия продукта */
  public getVersion(): Observable<{ assembly: string; dataBase: string }> {
    let url = `${this._baseUrl}`;
    return this._api.request<{ assembly: string; dataBase: string }>(
      url,
      null,
      'get',
      undefined,
      undefined,
      undefined,
      undefined
    );
  }

  /** Возвращает последнюю (актуальную) версию для каждого типа услуг API */
  public getActualVersion(query: {
    type: string;
    version?: number;
  }): Promise<{ actualVersions: [{ type: string; version: number }] } | undefined> {
    const url = `services${Utils.serialize(query)}`;
    return this._api
      .request<{ actualVersions: [{ type: string; version: number }] }>(
        url,
        null,
        'get',
        undefined,
        undefined,
        undefined,
        undefined,
        'version'
      )
      .toPromise();
  }

  /** обработчик ошибки от прехватчика
   * HttpErrorInterceptor при 503 коде вызывает этот метод
   * тут резолвиться логика обработки сбойной ситуацыи
   * */
  public ErrorVersionHandler() {
    // точка в которой возможна ощибка
    let routePoint = '';

    // структура url
    const treeURL = this._router.parseUrl(this._router.url);

    // квери параметры
    const queryParams = treeURL.queryParams;

    const primary = treeURL.root.children[PRIMARY_OUTLET];

    if (
      !!primary.segments.find(item => {
        return item.path === 'hotels';
      })
    ) {
      routePoint = 'hotels';
    }

    switch (routePoint) {
      case 'hotels':
        // устанавливаем url на котрый перенаправим
        let hrefRedirect = '';
        // если мы в командтровке
        if (primary.segments[0].path === 'hotels') {
          // перенаправляем на командировку
          if (primary.segments?.length > 2) {
            // если актуальная версия отелей 1
            if (this.hotelVersion === 1) {
              hrefRedirect = 'trips/edit/' + primary.segments[3].path;
            }
            // если актуальная версия отелей 2
            if (this.hotelVersion === 2) {
              hrefRedirect = 'trips/edit/' + primary.segments[2].path;
            }
          } else {
            // просмотр отелей из командировки
            hrefRedirect = 'hotels/search/';
            if (!!queryParams && !!queryParams['to'] && !!queryParams['return'] && !!queryParams['date']) {
              const qwery = `?to=${queryParams['to']}&date=${queryParams['return']}&return=${queryParams['date']}`;
              hrefRedirect = 'hotels/search/' + qwery;
            }
          }
        } else {
          // считаем что мы находимся в покупках во вне командировки
          hrefRedirect = 'services';
        }
        window.location.href = `${window.location.origin}/` + hrefRedirect;
        break;
    }
  }
}
