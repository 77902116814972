import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { IApiError } from '@app/core/interfaces/api-error.model';
import { IHttpError } from '@app/core/interfaces/http-error.interface';
import { TT } from '@app/core/misc/localization';
import { has } from 'lodash';

import { PopoverMessage } from './popover-message';

/**
 * класс ошибки при обращении к апи
 */
export class ApiError extends PopoverMessage implements IApiError {
  /** текст ошибки */
  private _message = '';

  response: HttpErrorResponse = new HttpErrorResponse({});

  request: HttpRequest<any> | undefined = undefined;

  /** код ошибки */
  public get code(): string {
    return this.response ? this.response.status.toString() : 'unknown';
  }

  public set code(val: string) {}

  /** сообщение ошибки */
  public get message(): string {
    // eslint-disable-next-line max-len
    if (this.response && this.response.error && this.response.error.message) {
      return this.response.error.message;
    }
    if (
      !this.response ||
      !this.errorDescription ||
      !this.errorDescription.error ||
      !this.errorDescription.error.message
    ) {
      return TT('Неустановленная ошибка');
    }
    if (this.code === '200') {
      return this._message;
    }
    if (this.code === '404' && !this.errorDescription.error) {
      return this.response.message;
    }
    let { message } = this.errorDescription.error;
    const validationMessages = this.errorDescription.error.details;

    if (!validationMessages || validationMessages.length === 0) return message;

    const validationErrors = validationMessages.map(x => x.message);

    if (validationErrors.length > 0) message += `<br />${validationErrors.join('<br />')}`;

    return message;
  }

  /** сообщение об ошибке нового образца */
  get fullMessage(): { message: string; comment: string } {
    // eslint-disable-next-line max-len
    let message = '';
    let comment = '';
    console.log(this.response)
    if (this.response?.error?.details?.message) {
      message = this.response?.error?.details?.message;
    } else {
      message = 'Неустановленная ошибка';
    }

    if (this.code === '200') {
      return { message: this._message, comment: '' };
    }
    if (this.code === '404' && !this.response.error) {
      return { message: this.response.message, comment: '' };
    }
    
    const validationMessages = this.response.error.details?.details;

    if (!validationMessages || validationMessages.length === 0) {
      return { message: message, comment: '' };
    }

    let validationErrors = null;
    if (Array.isArray(validationMessages)) {
      validationErrors = validationMessages.map(x => x.message);
    } else {
      validationErrors = validationMessages.message;
    }
    

    if (Array.isArray(validationErrors) && validationErrors.length > 0) comment += ` ${validationErrors.join(' ')}`;
    return { message: message, comment: comment };
  }

  public set message(val: string) {
    this._message = val;
  }

  /** детали ошибки */
  public get details(): Array<any> {
    return has(this, 'response.error.error.details') ? this.response.error.error.details : [];
  }

  public set details(val: Array<any>) {}

  /** описание ошибки */
  public get errorDescription(): IHttpError {
    return this.response.error;
  }

  /** признак критической ошибки */
  public get criticalError(): boolean {
    return this.code === '500';
  }

  constructor(data?: IApiError) {
    super();

    if (!data) return;

    // преобразуем ошибку из arraybuffer
    data.response = this._restoreErrorFromArrayBuffer(data.response);
    Object.assign(this, data);
  }

  public toJSON(): IApiError {
    const data: IApiError = {
      ...this,
      code: this.code,
      message: this.message,
      details: this.details,
    };
    return data;
  }

  /**
   * восстановление ответа сервера об ошибке из arraybuffer
   * так как ангуляр возвращает ошибку в том типе, в котором был запрос
   * @param error ошибка http
   */
  private _restoreErrorFromArrayBuffer(error: HttpErrorResponse): HttpErrorResponse {
    if (!(error.error instanceof ArrayBuffer)) {
      return error;
    }

    const clonedError = { ...error };
    const decodedString = String.fromCharCode(...new Uint8Array(error.error));
    clonedError.error = JSON.parse(decodeURIComponent(escape(decodedString)));
    return new HttpErrorResponse(clonedError as any);
  }
}
