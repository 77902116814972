import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPopoverMessage } from '@app/core/interfaces/popover-message.model';
import { TT } from '@app/core/misc/localization';
import { NotificationsService } from '@app/core/services/notifications.service';
import { Observable, throwError as observableThrowError, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { VersionService } from '@app/core/services/version.service';
import { ConfigService } from '../config.service';
import { ApiError } from '@app/core/class/api-error';

/**
 * перехват ошибок апи
 */
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _notificationsService: NotificationsService,
    private _config: ConfigService,
    private _versionService: VersionService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<ApiError | any> => {
        const apiError = new ApiError({
          title: TT('Внимание'),
          noButtons: false,
          request,
          response: error,
        });
        switch (error.status) {
          // ошибки связанные с версией
          case 503:
            Object.assign(apiError, <IPopoverMessage>{
              noButtons: false,
              // tslint:disable-next-line:max-line-length
              // eslint-disable-next-line max-len
              message: `${TT(
                'Версия API была изменена, вы будете перенаправлены на страницу поиска'
              )}<br/>`,
            });
            this._handleError(apiError);
            timer(2000).subscribe(() => {
              this._versionService.ErrorVersionHandler();
            });
            return this._handleError(apiError);
          case 401:
            // unauthorized
            // проходим флоу авторизации
            // this._oauthService.logOut();
            // this._storeService.clearStorage();
            window.location.href = `${window.location.origin}/`;
            return this._handleError(apiError);
          // обработка ошибок парсинга json: случай получения html на ответе 200 - протухание сессии
          case 200:
            // tslint:disable-next-line: no-angle-bracket-type-assertion
            Object.assign(apiError, <IPopoverMessage>{
              noButtons: false,
              // tslint:disable-next-line:max-line-length
              // eslint-disable-next-line max-len
              message: `${TT('Срок действия сессии истек')}<br/><br/> <a href="${
                this._config.globalConfig.loginUrl
              }">${TT('Вернуться на страницу входа')}</a>`,
            });
            return this._handleSessionExpiredError(apiError);
          default:
            return this._handleError(apiError);
        }
      })
    );
  }

  /**
   * обработчик всех ошибок кроме 401 и ошибки при протухании сессии
   * @param error объект ошибки
   */
  private _handleError(error: ApiError): Observable<ApiError> {
    // определяем есть ли заголовок молчания при ошибках
    const silent: boolean = error.request?.headers.get('silent') === '1';

    // передаем ошибку в канал данных
    if (!silent) this._notificationsService.emitError(error);

    return observableThrowError(error);
  }

  /**
   * обработчик ошибки протухания сессии
   * @param error объект ошибки
   */
  private _handleSessionExpiredError(error: ApiError): Observable<ApiError> {
    this._notificationsService.emitError(error);
    return observableThrowError(error);
  }
}
